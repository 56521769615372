<template>
  <div class="content" @click="console" v-if="loaded">
    <!--<h1>サンタクロースからの手紙</h1> 
   <h2>価格：1通 <STRIKE>1550円</STRIKE> → 
   <span class="red">早割価格1300円</span> 
   <br> +送料320円<span class="tax">（税込）</span></h2>-->
    <!--<div class="item_info"><div v-html="item.fields.info.value"></div></div>-->

    <div v-if="computed.campains.length" class="head_campain">
      <p>以下のキャンペーンが適用されます。</p>
      <dl v-for="(camp, cm) in computed.campains" :key="cm">
        <dt>{{ camp.title }}</dt>
        <dd>{{ camp.description }}</dd>
      </dl>
    </div>

    <p class="leadimage">
      <img src="../../assets/images/submitimg1.png" alt="" />
    </p>
 
    <div style="padding: 5em 0">
      サンタクロースからの手紙をご利用頂きありがとうございます。<br />
      本年度2023年のサンタクロースからの手紙のお申込受付を終了させて頂きました。
    </div>
  <!-- -->

    <dialogalert
      v-model="visible_error"
      :title="error_title"
      :info="error_info"
      button_ok="OK"
    />
    <fullloader :loading="fullloading" />
  </div>
</template>

<script>
//import { mapState, mapMutations, mapActions } from "vuex";
import plugin_itemoptions from "@/components/itemoption";
export default {
  components: { plugin_itemoptions },
  data: function () {
    return {
      typetag: "option1",
      value: [],
      computed: {},
    };
  },
  created: function () {
    this.fullloading = true;
    this.$parent.middle = 2;
    this.loaded_countmax = 2;
    //まず商品定義を読み込む
    //alert(this.$route.params.label)
    this.get_itemoption(this.$route.params.label).then(() => {
      this.options_filtered = this.options_filter("option1");

      //カートが空だった場合は1つ目の商品のIDを設定
      if (
        this.record.fields.items.subfields.length == 1 &&
        !this.record.fields.items.subfields[0].item.value
      ) {
        this.record.fields.items.subfields[0].item.value = this.item.ID;
        let adcode = this.getAdcode().join(",");
        this.record.fields.adcode.value = adcode;
      }
      this.get_totalamount().then((data) => {
        console.log(data);
        this.computed = data;
        this.loaded_countup();
      });

      //console.log("options_filtered",this.options_filtered);
      //this.option_available=true;
      this.loaded_countup();
      this.hashScroll();
    });
  },
  computed: {
    //...mapState([]),
    label: function () {
      return this.$route.params.label;
    },
  },
  methods: {
    console: function () {
      console.log("this.record(index.vue):", this.record);
    },
    submit: function () {
      this.scrollToTop();
      this.fullloading = true;
      var record = this.getSession("record");
      //ログイン済みの場合はトークン、非会員の場合はuserを「-1」にする。
      var token = this.get_localStorage("token");
      if (!token) record.user = "-1";
      this.fetch("order_submit", {
        token: token,
        record: record,
        mode: "check",
      }).then((data) => {
        console.log(data);
        if (typeof data.record != "undefined")
          this.$set(
            this.record,
            "fields",
            Object.assign(this.record.fields, data.record.fields)
          );
        this.errors_global = {};
        if (typeof data.error != "undefined") {
          this.errors_global = data.error;
          console.log(data.error);
          this.error_info = "不備の項目があります。<br>";
          var filtered_ids = [];
          for (let i = 0; i < this.options_filtered.length; i++) {
            filtered_ids.push(this.options_filtered[i].ID);
          }
          for (let i = 0; i < data.error.length; i++) {
            let optionID = data.error[i].id.match(
              /^field_items-([0-9]+)-option([0-9]+)$/
            );
            if (optionID !== null) console.log(filtered_ids, optionID[2]);
            if (optionID !== null && filtered_ids.indexOf(optionID[2]) != -1) {
              //if(this.unit <= optionID[1])continue;
              this.error_info += "・ " + data.error[i].msg + "<br>";
              this.visible_error = true;
            }
          }
        }

        this.fullloading = false;
        if (!this.visible_error) {
          if (this.$route.query.rd == "back") {
            this.$router.back();
          } else {
            this.$router.push("/item/" + this.label + "/option1");
          }
        }
      });
    },
    //...mapMutations([]),
    //...mapActions([]),
  },
};
</script>
<style lang="scss">
$sp: 480px; // スマホ
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.item_info {
  h1 {
    margin-top: 1em;
    color: #d72e23;
    font-size: 2em;
    @include sp {
      font-size: 1.4em;
    }
  }
  h2 {
    motion-path: 1.5em;
    @include sp {
      font-size: 1.3em;
    }
  }
  .red {
    color: rgb(200, 34, 34);
    display: inline-block;
    font-size: 1.05em;
  }
}
</style>
<style scoped lang="scss">
$sp: 480px; // スマホ
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.content {
  color: #000;
}
.tax {
  font-size: 0.8em;
}
h1 {
  margin-top: 1em;
  color: #d72e23;
  font-size: 2em;
  @include sp {
    font-size: 1.4em;
  }
}
h2 {
  motion-path: 1.5em;
  @include sp {
    font-size: 1.3em;
  }
}
.loadingContainer {
  padding: 5em 0;
  color: rgb(71, 71, 71);
}
.head_campain {
  border-radius: 0.2em;
  margin: 0.5em 0;
  padding: 1em;
  border: solid 1px #ccc;
  background: rgb(240, 238, 248);
  p {
    font-size: 0.8em;
    font-weight: bold;
  }
  dl {
    padding: 1em;
    dt {
      margin-bottom: 0.7em;
      color: #c82222;
    }
    dd {
      font-size: 0.9em;
    }
  }
}

.steps .stepcontent .order_unit {
  p {
    font-size: 0.7em;
    margin-top: 0;
    margin-left: 1em;
    display: inline-block;
    @include sp {
      margin-top: 0.8em;
      margin-left: 0;
    }
  }
}
</style>