<template>
  <div id="app">
    <div id="header">
      <div class="headerinner">
        <ul id="spmn" class="sp">
          <li v-if="!logined">
            <router-link @click.native="scrollToTop" to="/join"></router-link>
          </li>
          <li v-else>
            <router-link
              @click.native="scrollToTop"
              to="/mypage/"
            ></router-link>
          </li>
        </ul>
        <p class="sitelogo">
          <router-link @click.native="scrollToTop" to="/">
            <img src="./assets/images/logo1.png" alt="" />
          </router-link>
        </p>
        <ul id="topmn">
          <li class="mn1">
            <router-link @click.native="scrollToTop" to="/">トップ</router-link>
          </li>

          <li class="mn2" v-if="!logined">
            <router-link @click.native="scrollToTop" to="/join"
              >ログイン・会員登録</router-link
            >
          </li>

          <li class="mn2" v-else>
            <router-link @click.native="scrollToTop" to="/mypage/"
              >マイページへ</router-link
            >
          </li>

          <li class="mn3">
            <router-link
              @click.native="scrollToTop"
              to="/item/santa2024_standard/"
              >サンタクロースからの手紙<br />お申込み</router-link
            >
          </li>

          <li class="mn4" v-if="!logined">
            <router-link @click.native="scrollToTop" to="/status"
              >お申込み状況の確認</router-link
            >
          </li>
          <li class="mn4" v-else>
            <router-link @click.native="scrollToTop" to="/mypage/order_history"
              >お申込み状況の確認</router-link
            >
          </li>
        </ul>
        <p class="sitemenu sp" @click="mnvisible = true"></p>
      </div>
    </div>
    <div id="middlecontent" v-if="middle == 1">
      <div class="notice" v-if="common.news && common.news.length">
        {{ fetchCurrentDate("YYYY.MM.DD", common.news[0].page_open) }}
        最新情報：{{ common.news[0].page_title }}
      </div>

      <div class="slide">
        <img src="./assets/images/flimg1end2.jpg" alt="" />
      </div>
      <div class="notice2">
        本年度のサンタクロースからの手紙の受付は終了いたしました。
      </div>
      <!--

      <div class="slide">
        <router-link @click.native="scrollToTop" to="/item/santa2024_standard/"
          ><img src="./assets/images/flimg1.png" alt=""
        /></router-link>
      </div>
      <div class="notice2">
        今年も大切な方へ、心を込めてサンタクロースからの手紙をお届けします
      </div>-->
    </div>

    <div id="content" :class="'middle' + middle">
      <div class="main">
        <!--<button @click="setTest('..chenged! ')">変える</button>-->
        <router-view />

        <div id="nav" v-if="0">
          <router-link @click.native="scrollToTop" to="/">トップへ</router-link>
          |
          <router-link @click.native="scrollToTop" to="/informations/news"
            >最新情報</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/item/santa2021/"
            >申し込みはこちら</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/informations/about"
            >サンタクロースからの手紙って？</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/informations/guide"
            >ご利用ガイド</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/informations/history"
            >封筒ギャラリー</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/informations/safety"
            >安心安全を追求します</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/informations/qa"
            >お問い合わせ・Ｑ＆Ａ</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/mypage/"
            >マイページ</router-link
          >
          |
          <router-link @click.native="scrollToTop" to="/join"
            >新規会員登録</router-link
          >
        </div>
      </div>
    </div>
    <div id="footer">
      <div class="footerinner">
        <div class="footerlogo sp">
          <img src="./assets/images/logo1.png" alt="" />
        </div>
        <ul class="footmn">
          <li>
            <router-link @click.native="scrollToTop" to="/informations/about"
              >サンタクロース大使館について</router-link
            >
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/informations/terms"
              >ご利用規約</router-link
            >
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/informations/privacy"
              >プライバシーポリシー</router-link
            >
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/informations/guide"
              >ご利用ガイド</router-link
            >
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/informations/qa"
              >お問合せ</router-link
            >
          </li>
        </ul>
        <ul class="footmn2">
          <li>
            <router-link @click.native="scrollToTop" to="/informations/law"
              >特定商取引法に基づく表記
            </router-link>
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/informations/company"
              >会社概要
            </router-link>
          </li>
          <li>
            <router-link @click.native="scrollToTop" to="/informations/mailmag"
              >メールマガジン
            </router-link>
          </li>
          <li>
            <router-link
              @click.native="scrollToTop"
              to="/informations/wholesele"
              >法人販売
            </router-link>
          </li>
        </ul>
        <div class="footcopy">
          Copyright © 2019 sukat-communications co,.Ltd. All rights reserved.<br />【無断転載・無断複写は禁止いたします】<br />[ver.{{
            appVersion
          }}]
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="spmenu" v-if="mnvisible">
        <div class="closebn" @click="mnvisible = !mnvisible">×</div>
        <sideContents />
        <div class="totopbn">
          [<router-link @click.native="mnvisible = false" to="/"
            >トップページへ戻る</router-link
          >]
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
//import { mapState ,mapMutations } from 'vuex'
export default {
  errorCaptured(err, vm, info) {
    let errtxt = err.toString();
    try {
      errtxt += "\n\n-------------\n" + this.$router.currentRoute.path;
    } catch (e) {}
    try {
      errtxt += "\n\n-------------\n" + err.stack;
    } catch (e) {}
    try {
      errtxt += "\n\n-------------\n" + err.info;
    } catch (e) {}

    this.myDebug(errtxt);
  },
  created: function () {
    if (/^#\//.test(window.location.hash)) {
      var newurl = window.location.hash.replace(/^#/, "");
      console.log("ne", newurl);
      window.location.href = newurl;
    }
    console.log("arg:,window.location.hash", window.location.hash);
    //adcodeの保存
    if (this.$route.query.adcode) {
      //this.setSession("adcode", this.$route.query.adcode);
    }

    //var unit = this.getSession("unit");
    //if (unit) this.unit = unit - 0;
    this.token = this.getSession("token");

    this.login("news,getall").then((data) => {
      console.log(data);
      console.log("mergeCommon", this.common);
    });
  },
  data: function () {
    return {
      token: "",
      middle: 2,
      mnvisible: false,
    };
  },
  computed: {
    /*
     ...mapState([
      'token'
    ])*/
  },
  methods: {
    //...mapMutations([])
    //setTest() {
    //this.$store.commit("setTest", "changed!..")
    //store.commit("setTest", "changed!..")
    //this.teststore = this.teststore + "changed!..";
    //}
  },
};
</script>


<style lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.spmenu {
  z-index: 100;
  background: #fff;
  padding-top: 15vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  .closebn {
    position: fixed;
    background: rgba(255, 255, 255, 0.753);
    line-height: 15vw;
    width: 15vw;
    right: 0vw;
    top: 0vw;
    font-size: 15vw;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#header {
  color: #fffef8;
  background: #303e6d;
  .headerinner {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0.5em;
  }
  .sitelogo {
    width: 276px;
    @include sp {
      width: 40%;
    }
    a {
    }
    img {
      max-width: 100%;
    }
  }
  .sitemenu {
    background: url("./assets/images/icon_smttop2.png") no-repeat center /
      contain;
    width: 15vw;
    height: 15vw;
  }

  ul#topmn {
    @include sp {
      display: none;
    }
    flex-grow: 100;
    list-style: none;
    justify-content: right;
    display: flex;
    flex-direction: row;
    li {
      font-size: 0.7em;
      a {
        display: block;
        color: #fff;
        width: 190px;
        padding-top: 60px;
      }
    }
    a {
      text-decoration: none;
    }
    .mn1 {
      background: url("./assets/images/mnicon1.png") no-repeat top center / 60px;
    }
    .mn2 {
      background: url("./assets/images/mnicon2.png") no-repeat top center / 60px;
    }
    .mn3 {
      background: url("./assets/images/mnicon3.png") no-repeat top center / 60px;
    }
    .mn4 {
      background: url("./assets/images/mnicon4.png") no-repeat top center / 60px;
    }
  }
  #spmn {
  }

  #spmn a {
    background: url("./assets/images/icon_smttop1.png") no-repeat center /
      contain;
    display: block;
    width: 15vw;
    height: 15vw;
  }
}
#content {
  color: #fff;
  background: #202533;
  .main {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;

    @include sp {
      padding-top: 6vw;
    }
  }
}
#content.middle2 {
  background: #fff;
  color: #202020;
}

#footer {
  .footerlogo {
    padding-top: 1em;
    img {
      width: 45%;
    }
  }
  color: #fff;
  background: #303e6d;
  .footerinner {
    max-width: 1000px;
    margin: 0 auto;
  }
  a {
  }
  ul.footmn,
  ul.footmn2 {
    padding: 2em 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    font-size: 0.7em;
    justify-content: center;
    @include sp {
      flex-direction: column;
    }
    li {
      margin-right: 1em;
      padding-right: 1em;
      border-right: solid 1px #fff;
      @include sp {
        margin-bottom: 0.7em;
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
      a {
        text-decoration: none;
        color: #fff;
      }
    }
    li:last-child {
      padding-right: 0;
      border: none;
      margin-right: 0;
    }
  }
  ul.footmn2 {
    padding-top: 0;
  }
  .footcopy {
    line-height: 1.9;
    font-size: 0.7em;
    padding-bottom: 4em;
  }
}

.allclearbn {
  position: absolute;
  top: 0.3em;
  right: 0;
  border: solid 1px rgb(114, 114, 114);
  font-size: 0.65rem;
  background: #fff;
  color: rgb(68, 68, 68);
  &:hover {
    background: #d72e23;
    color: #fff;
  }
}
@media screen and (max-width: 800px) {
}
</style>
